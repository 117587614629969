<template>
  <Layout>
    <div class="main-shop-cart">
      <div class="base-width">
        <!-- 搜索 -->
        <!-- <Search type  v-ani.fade-up></Search> -->
        <div class="c-title-2 s22" v-ani.fade-up>全部商品（{{ total_count | numMax }}）</div>
        <div class="md-shop-cart">
          <!-- 表头 -->
          <div class="thead s16 color-2 font-medium" v-ani.fade-up>
            <div class="td">
              <BaseSwitch v-model="checkAll" @change="handleAllCheck">全选</BaseSwitch>
            </div>
            <div class="td">商品名称</div>
            <div class="td">单价</div>
            <div class="td">数量</div>
            <div class="td">小计</div>
            <div class="td">操作</div>
          </div>
          <!-- 商品 -->
          <div class="tbody s16 color-4">
            <template v-for="(item, index) in cartArr">
              <!-- 店铺 -->
              <div class="store" :key="item.id" :class="{ check: item.check }" v-ani.fade-up>
                <!-- 店铺名称 -->
                <div class="store-info flex-middle">
                  <div class="td">
                    <BaseSwitch v-model="item.check" @change="handleCheckStore($event, index)"></BaseSwitch>
                  </div>
                  <div class="td">
                    <ProductStoreName :shop_name="item.shop_name" :shop_type="item.shop_type"
                      :suppliers_id="item.suppliers_id" />
                  </div>
                </div>
                <!-- 商品列表 -->
                <div class="store-goods-list">
                  <!-- 商品 -->
                  <div class="store-goods" v-for="(goods, goods_index) in item.list" :key="goods.id"
                    :class="{ out: goods.out, check: goods.check }">
                    <div class="td">
                      <BaseSwitch v-model="goods.check" @change="handleCheckGoods($event, index, goods_index)">
                      </BaseSwitch>
                    </div>
                    <div class="td">
                      <ProductItemChoosed :goods="goods" />
                    </div>
                    <div class="td">
                      <div class="price">¥{{ goods.goods_price }}</div>
                    </div>
                    <div class="td">
                      <BaseProductNumber tips :all="goods.store_count" :max="goods.store_count" :min='1'
                        :disabled="!goods.store_count" v-model="goods.goods_num"
                        @change="proNunChange($event, index, goods_index)" />
                    </div>
                    <div class="td">
                      <div class="subtotal color-red s22 font-medium">
                        ¥{{ getSubtotal(goods.goods_price, goods.goods_num) }}
                      </div>
                    </div>
                    <div class="td">
                      <div class="operate text-center">
                        <p class="btn btn-delete" @click="delClick(index, goods_index)">删除</p>
                        <p class="btn btn-collect">移进收藏</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-ani.fade-up>
            <!-- 地址 -->
            <div class="address s16 color-6 flex-middle">
              <p>收货地址: {{ addressObj.consignee }} {{ addressObj.mobile }} {{ addressObj.province_name }}{{
                addressObj.city_name }}{{ addressObj.district_name }}{{ addressObj.address }}</p>
              <!-- <router-link to="" class="icon">
                <img src="@/assets/images/icon-edit.png" alt="">
              </router-link> -->
            </div>
            <!-- 汇总 -->
            <div class="tfoot s16 color-2 font-medium flex-middle">
              <div class="lf flex-middle">
                <BaseSwitch v-model="checkAll" @change="handleAllCheck">全选</BaseSwitch>
                <div class="btn-delete" @click="handleDeleteChoosed">删除选中的商品</div>
              </div>
              <div class="rg flex-middle">
                <p class="num">已选择{{ selectNum }}件商品</p>
                <div class="total">总价：<span class="s26 color-red">¥{{ totalPrice.toFixed(2) }}</span></div>
                <div class="btn-submit s18 color-f font-bold" @click="settlementClick">去结算</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除弹窗 -->
    <BaseDialog :visible.sync='dialog_delete' title="提示" width="5rem" @confirm='deleteConfirm'>
      <div class="s22 color-2 font-medium text-center" style="margin: .6rem 0">确认删除？</div>
    </BaseDialog>
  </Layout>
</template>

<script>
import { CartListApi, CartDelApi, CartSelectApi, CartAddApi } from "@/request/api/shop";
import { AddressDefaultApi } from "@/request/api/order.js";
export default {
  created() {
    this.cartFn();
    this.getDefaultAddressFn();
  },
  data() {
    return {
      cartArr: [],
      addressObj: {},
      total_count: 0,
      selectNum: 0,
      totalPrice: 0,
      checkAll: false,
      dialog_delete: false,
      delete_index: []
    };
  },
  methods: {
    // 获取默认地址
    getDefaultAddressFn() {
      AddressDefaultApi().then(({ status, data }) => {
        if (status == 200) {
          this.addressObj = data;
        }
      })
    },
    getSubtotal(price, num) {
      let res = price * num
      return res.toFixed(2)
    },
    // 结算
    settlementClick() {
      let ids = [];
      this.cartArr.map(item => {
        if (item.list.length > 0) {
          item.list.map(el => {
            if (el.check) {
              ids.push(el.id);
            }
          })
        }
      })
      if (ids.length <= 0) {
        this.$message.warning('请选择商品');
        return
      }
      this.$router.push('/order-fill?fromType=1');

      // CartSelectApi({ ids: ids.join('') }).then(({ status, data }) => {
      //   if (status == 200) {
      //     this.$router.push('/order-fill?fromType=1');


      //   }
      // })
    },
    // 全选
    handleAllCheck(val) {
      this.cartArr.forEach(el => {
        el.check = val
        el.list.forEach(item => {
          item.selected = val;
          item.check = val;
        })
      })
      this.judgeAll();
    },
    // 店铺选择
    handleCheckStore(val, i) {
      this.cartArr[i].list.forEach(el => {
        el.check = val
      })
      this.judgeAll()
    },
    // 商品选择
    handleCheckGoods(val, i, gi) {
      this.cartArr[i].list[gi].check = val;
      let bool = this.cartArr[i].list.every(el => el.check)
      this.cartArr[i].check = bool
      this.judgeAll();
    },
    judgeAll() {
      let ids = [];
      let idNo = []
      this.cartArr.map(item => {
        if (item.list.length > 0) {
          item.list.map(el => {
            if (el.check) {
              ids.push(el.id);
            } else {
              idNo.push(el.id);
            }
          })
        }
      })
      if (ids.length > 0) {
        //  购物车选中
        CartSelectApi({ ids: ids.toString(), status: 1 }).then(({ status, data }) => {
          if (status == 200) { }
        })
      }
      if (idNo.length > 0) {
        CartSelectApi({ ids: idNo.toString(), status: 2 }).then(({ status, data }) => {
          if (status == 200) { }
        })
      }

      
      this.selectNum = 0; // 选择的商品数量
      this.totalPrice = 0; // 商品总价
      this.cartArr.map((item, index) => {
        let bool = this.cartArr[index].list.every(el => el.check);
        this.cartArr[index].check = bool;
        if (item.list.length > 0) {
          item.list.map(el => {
            if (el.check == 1) {
              el.check = true;
              this.selectNum++;
              (this.totalPrice += el.goods_num * el.goods_price).toFixed(2);
            } else {
              el.check = false;
            }
          })
        }
      })
      this.checkAll = this.cartArr.every(el => el.check);  // 全选

    },
    /**
     * 单个删除
     * @param {Number} i 店铺索引
     * @param {Number} j 商品索引
     */
    delClick(i, j) {
      this.delete_index = [];
      this.delete_index.push(this.cartArr[i].list[j].id);
      this.dialog_delete = true;
    },
    // 删除选中的
    handleDeleteChoosed() {
      this.delete_index = [];
      this.cartArr.map(item => {
        if (item.list.length > 0) {
          item.list.map(el => {
            if (el.check) {
              this.delete_index.push(el.id);
            }
          })
        }
      })
      if (this.delete_index.length <= 0) {
        this.$message.warning('请选择商品');
        return
      }
      this.dialog_delete = true;
    },
    // 删除弹框确认
    deleteConfirm() {
      CartDelApi({ ids: this.delete_index.toString() }).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('删除成功');
          this.cartFn();
          this.dialog_delete = false;
        }
      })
    },
    // 购物车列表
    cartFn(isJu) {
      CartListApi().then(({ status, data }) => {
        if (status == 200) {
          data.list.map(item => {
            item.check = false;
            if (item.list.length > 0) {
              item.list.map(el => {
                if (el.selected == 1) {
                  el.check = true;
                } else {
                  el.check = false;
                }
              })
            }
          })
          this.cartArr = data.list;
          this.total_count = data.total_count;
          this.judgeAll();
        }
      })
    },
    // 数量改变 i 店铺索引 j 商品索引
    proNunChange(e, i, j) {
      CartAddApi({
        goods_id: this.cartArr[i].list[j].goods_id,
        goods_num: this.cartArr[i].list[j].goods_num,
        goods_spec: this.cartArr[i].list[j].spec_key,
      }).then(({ status, data }) => {
        if (status == 200) {

        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.main-shop-cart {
  padding-bottom: .4rem;
}

.c-title-2 {
  margin-top: .36rem;
}

.md-shop-cart {
  padding-top: .14rem;

  .thead {
    display: flex;
    align-items: center;
    height: .7rem;
    background: #fff;
    border: solid 1px #e6f2f8;

    .td {
      &:nth-child(1) {
        width: 2.09rem;
        padding-left: 0.3rem;
      }

      &:nth-child(2) {
        width: 6.87rem;
      }

      &:nth-child(3) {
        width: 1.9rem;
      }

      &:nth-child(4) {
        width: 1.9rem;
      }

      &:nth-child(5) {
        width: 1.9rem;
      }

      &:nth-child(6) {
        flex: 1;
      }
    }
  }

  .tbody {
    .td {
      &:nth-child(1) {
        width: .76rem;
        padding-left: .3rem;
      }

      &:nth-child(2) {
        width: 7.6rem;
        justify-content: flex-start !important;
      }

      &:nth-child(3) {
        width: 1.5rem;
      }

      &:nth-child(4) {
        width: 2.3rem;
      }

      &:nth-child(5) {
        width: 1.57rem;
      }

      &:last-of-type {
        flex: 1;
      }
    }
  }

  .store {
    border: solid 1px #e6f2f8;
    background: #fff;
    margin-top: .2rem;

    &-info {
      border-bottom: 1px solid #e6f2f8;

      .td {
        padding: .1rem 0;
      }
    }

    &-goods-list {
      .td {
        padding: .3rem 0;

        &:not(:first-child) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-goods {
      display: flex;
      border-bottom: 2px solid #e6f2f8;

      &:last-of-type {
        border: none;
      }

      .operate {
        .btn {
          cursor: pointer;
          margin: .15rem 0;

          &-delete {
            &:hover {
              color: $red;
            }
          }

          &-collect {
            &:hover {
              color: $brown;
            }
          }
        }
      }

      &.out {

        .price,
        .subtotal,
        .btn-collect {
          color: #999;
        }

        ::v-deep .c-product-number .form .ipt {
          color: #999;
        }
      }

      &.check {
        background: #f4f9fd;
      }
    }

    &.check {
      background: #f4f9fd;
    }
  }

  .address {
    height: .5rem;
    background-color: #f4f9fd;
    border-radius: .04rem .04rem 0 0;
    border: solid 1px #dbecff;
    padding: 0 .3rem;
    margin-top: 0.2rem;

    .icon {
      margin-left: .18rem;
      font-size: 0;

      img {
        width: .16rem;
      }
    }
  }

  .tfoot {
    justify-content: space-between;
    height: .7rem;
    background-color: #ffffff;
    border-radius: 0 0 .04rem .04rem;
    padding-left: .3rem;

    .btn-delete {
      margin-left: .2rem;
      cursor: pointer;

      &:hover {
        color: $red;
      }
    }

    .num {
      margin-top: .08rem;
    }

    .total {
      margin-left: 0.6rem;
    }

    .btn-submit {
      width: 1.4rem;
      line-height: .7rem;
      background-color: #e01624;
      text-align: center;
      margin-left: .45rem;
      cursor: pointer;
    }
  }
}
</style>